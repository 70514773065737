import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import './InvoiceSummary.css';


const InvoiceSummary = ({order}) => {
    const totalShippingCost = useSelector(state => state.orders.totalShippingCost);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const calculateTotal = () => {
            let total = order.box_details.reduce(
                (accumulator, detail) => accumulator + detail.product_quantity * order.box_price,
                0
            );

            total += parseFloat(totalShippingCost) || 0; // Ensure totalShippingCost is a number

            return total.toFixed(2);
        };

        setTotal(calculateTotal());
    }, [totalShippingCost, order.box_details, order.box_price]);

    return (
        <div className="invoice-summary-container">
            <div className="invoice-details">
                <div className="order-info-invoice">
                    <div className="order-info">
                        <p>{order.order_id}</p>
                        <p>{new Date(order.created_at).toLocaleDateString()}</p>
                    </div>
                    {order.box_details.map((detail, index) => (
                        <div key={index} className="product-detail">
                            <div className="invoice-details-name-container">
                                <p className="invoice-details-name">{detail.product_name}</p>
                            </div>
                            <div className="invoice-details-container">
                                <p className="invoice-details-product-quantity"> {detail.product_quantity} box(s)</p>
                                <p className="invoice-details-product-box-price">${order.box_price} per box</p>
                            </div>
                        </div>
                    ))}
                    <p className="invoice-total-shipping"><strong>SHIPPING:</strong> ${parseFloat(totalShippingCost).toFixed(2)}</p>
                    <div className="invoice-total-details-container">
                        <p className="invoice-total-label"><strong>TOTAL:</strong></p>
                        <p className="invoice-total-amount">${total}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceSummary;
