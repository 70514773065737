import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMroScreenFullDetails } from '../../services/api/v1/mroScreenService';

// Async thunk for fetching MRO screen details
export const getMroScreenDetails = createAsyncThunk(
    'mroScreens/getMroScreenDetails',
    async ({screenParams}, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        const formData = new FormData();
        formData.append('start_date', screenParams.filters.date_range?.start_date);
        formData.append('end_date', screenParams.filters.date_range?.end_date);
        return await fetchMroScreenFullDetails(formData, accessToken);
    }
);

// Initial state for the MRO screen slice
const initialState = {
    screens: [],
    status: 'idle',
    error: null,
};

// Slice for MRO screens
const mroScreenSlice = createSlice({
    name: 'mroScreens',
    initialState,
    reducers: {
        // Reducer to clear screen data
        clearScreenData: state => {
            state.screens = [];
            state.status = 'idle';
            state.error = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getMroScreenDetails.pending, state => {
                state.status = 'loading';
            })
            .addCase(getMroScreenDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.screens = action.payload; // Assuming the payload is the array of screens
            })
            .addCase(getMroScreenDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

// Export the reducer
export const { clearScreenData } = mroScreenSlice.actions;
export default mroScreenSlice.reducer;
