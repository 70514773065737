const BillableEventsSummary = ({ totalBillableCount = 0 }) => {
    return (
        <header className="billable-events-summary">
            <div className="summary-info">
                <p className="total-billable-events">
                    <strong>Total Billable Events:</strong> {totalBillableCount}
                </p>
            </div>
        </header>
    );
};

export default BillableEventsSummary;