import {PrimaryButton} from "../../../components/buttons/primary_button/PrimaryButton";

export const SignInPage = () => {

    const handleShopifyLogin = () => {

    }


    return (
        <div>
            <PrimaryButton onClick={handleShopifyLogin}>Sign In</PrimaryButton>
        </div>
    );
}