import React, { useEffect, useState } from "react";
import "./MedicalReviewOfficerPage.css";
import { useDispatch, useSelector } from "react-redux";
import MroSpecimensTable from "../../components/specimens/mro_screens/MroSpecimensTable";
import { getMroScreenDetails } from "../../store/mro_screen/mroScreenSlice";
import { getMroReportPdfRecords } from "../../store/mro_reports/mroReportsSlice";
import { showMessageModal } from "../../store/modals/modalsSlice";
import { subDays } from "date-fns";
import AdminHeader from "../../components/headers/admin_header/AdminHeader";

const MedicalReviewOfficerPage = () => {
    const dispatch = useDispatch();

    // Calculate default date range: last 14 days (once)
    const today = new Date();
    const fourteenDaysAgo = subDays(today, 14);
    const defaultDateRangeObj = {
        start_date: fourteenDaysAgo.toISOString().slice(0, 10),
        end_date: today.toISOString().slice(0, 10)
    };

    // Initialize screenParams with default date_range
    const [screenParams, setScreenParams] = useState({
        offset: 0,
        limit: 20,
        filters: {
            date_range: defaultDateRangeObj
        }
    });

    const [isLoading, setIsLoading] = useState(false);
    const mroScreens = useSelector((state) => state.mro_screens);
    const mroScreensArray = mroScreens.screens;
    const pdfRawData = useSelector((state) => state.reports?.reports?.payload);

    // Show error message if request fails
    useEffect(() => {
        if (mroScreens.status === "failed") {
            dispatch(showMessageModal({ message: mroScreens.error, link: null }));
        }
    }, [mroScreens.status, dispatch]);

    // If PDF data is available and we have specimens, trigger download
    useEffect(() => {
        if (pdfRawData && mroScreensArray.length > 0) {
            const specimenId = mroScreensArray[0].id;
            downloadMroReportPdf(pdfRawData, specimenId);
        }
    }, [pdfRawData, mroScreensArray]);

    const downloadMroReportPdf = (pdfUrl, specimenId) => {
        try {
            const link = document.createElement("a");
            link.href = pdfUrl.pdf_url;
            link.download = `${specimenId}-final-report.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            dispatch(
                showMessageModal({
                    message: "Download initiated successfully.",
                    link: pdfUrl.pdf_url,
                })
            );
        } catch (error) {
            dispatch(showMessageModal({ message: "Failed to initiate download.", link: null }));
        }
    };

    const handleDownloadButtonClick = async (initialScreenData) => {
        setIsLoading(true);
        try {
            const response = await dispatch(
                getMroReportPdfRecords({
                    service_request_id: initialScreenData.service_request_id,
                    date_range: {
                        start_date: screenParams.filters.date_range.start_date,
                        end_date: screenParams.filters.date_range.end_date,
                    },
                })
            );
            if (response.error) {
                throw new Error(response.error.message);
            }
            dispatch(
                showMessageModal({
                    message: "Download started successfully.",
                    link: response.payload.pdf_url,
                })
            );
            return response.payload;
        } catch (error) {
            dispatch(showMessageModal({ message: "Failed to start download.", link: null }));
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    // Dispatch the initial request on mount with screenParams that include a valid date_range.
    useEffect(() => {
        dispatch(getMroScreenDetails({ screenParams }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // Provide date picker with an array of dates
    const defaultDateRange = [fourteenDaysAgo, today];

    const onFilterChange = (filterName, value) => {
        // Update screenParams and dispatch a new request
        const updatedParams = {
            ...screenParams,
            offset: 0,
            filters: {
                ...screenParams.filters,
                [filterName]: value,
            },
        };
        setScreenParams(updatedParams);
        dispatch(getMroScreenDetails({ screenParams: updatedParams }));
    };

    return (
        <div className="screens-page-container">
            <div className="screens-header-container">
                <AdminHeader />
            </div>
            <div className="screens-body-container">
                <div className="screens-content-container">
                    <MroSpecimensTable
                        screensArray={mroScreensArray}
                        onDownload={handleDownloadButtonClick}
                        defaultDateRange={defaultDateRange}
                        onFilterChange={onFilterChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default MedicalReviewOfficerPage;
