// freddy-react/src/store/store.js

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import screensSlice from './screens/screensSlice';
import autobotReducers from "./autobot";
import organizationsSlice from "./autobot/clients/organizations/organizationsSlice";
import screenSlice from "./screen/screenSlice";
import deviceIdReducer from './device/deviceSlice';
import emailsReducer from './emails/emailsSlice';
import testingReasonSlice from "./testing_reasons/testingReasonSlice";
import cynergyRedArrowInvoiceReducer from "./invoices/cynergyRedArrowInvoiceSlice";
import cynergyWorxInvoiceReducer from "./invoices/cynergyWorxInvoiceSlice";
import mroExpressInvoiceReducer from "./invoices/mroExpressInvoiceSlice";
import questInvoiceReducer from "./invoices/questInvoiceSlice";
import omegaInvoiceReducer from "./invoices/omegaInvoiceSlice";
import desertToxInvoiceReducer from "./invoices/desertToxInvoiceSlice";
import shopifyUserReducer from './user/shopifyUserSlice';
import filtersReducer from './filters/filtersSlice';
import questAccountsSlice from "./external/questAccountsSlice";
import zohoAuthSlice from "./external/zohoAuthSlice";
import upsAuthSlice from "./external/upsAuthSlice";
import emailAuthSlice from "./external/emailAuthSlice";
import clientsSlice from "./clients/clientsSlice";
import notificationsSlice from "./notifications/notificationsSlice";
import navbarSlice from './navbar/navbarSlice';
import laboratorySlice from "./laboratory/laboratorySlice";
import mroScreenReducer from './mro_screen/mroScreenSlice';
import mroReportsReducer from './mro_reports/mroReportsSlice';
import laboratoryAccountsSlice from "./laboratory/laboratoryAccountSlice";
import clientBillingDetailsSlice from "./clients/clientBillingDetailsSlice";
import servicePricesSlice from "./prices/servicePricesSlice";
import badganatorSlice from "./badganator/badganatorSlice";
import screenersSlice from "./screener/screenerSlice";
import ordersSlice from "./orders/ordersSlice";
import PanelsSlice from "./panels/panelsSlice";
import shopifyAuthSlice from "./external/shopifyAuthSlice";
import lssProductsReducer from "./products/lssProductsSlice";
import shopifyProductsReducer from "./products/shopifyProductsSlice";
import {linkedShopifyProductsSlice} from "./products/productsSlice";
import questServicesSlice from "./services/questServicesSlice";
import speedCartReducer from "./shopify/cart/shopifySpeedCartSlice";
import publicUserReducer from "./user/publicUserSlice";
import modalsSlice from "./modals/modalsSlice";
import quickbooksAuthSlice from "./external/quickbooksAuthSlice";
import productPricesSlice from "./prices/productPricesSlice";
import questOrderSlice from "./orders/questOrderSlice";
import questEspSlice from "./quest_esp/questEspSlice";
import questAccountRequestSlice from "./laboratory/questAccountRequestSlice";
import clientScreensSlice from "./clients/clientScreensSlice";
import screenNotesSlice from "./screens/screenNotesSlice";
import clientEventsSlice from "./events/eventSlice";

const store = configureStore({
    reducer: {
        user: userReducer,
        publicUser: publicUserReducer,
        shopifyUser: shopifyUserReducer,
        shopifyCart: speedCartReducer,
        screens: screensSlice,
        screen: screenSlice,
        navbar:navbarSlice,
        modals: modalsSlice.reducer,
        screeners: screenersSlice,
        autobot: autobotReducers,
        organizations: organizationsSlice,
        deviceId: deviceIdReducer,
        testingReasons: testingReasonSlice,
        cynergyRedArrowInvoice: cynergyRedArrowInvoiceReducer,
        cynergyWorxInvoice: cynergyWorxInvoiceReducer,
        mroExpressInvoice: mroExpressInvoiceReducer,
        questInvoice: questInvoiceReducer,
        omegaInvoice: omegaInvoiceReducer,
        desertToxInvoice: desertToxInvoiceReducer,
        filters: filtersReducer,
        emails: emailsReducer,
        questAccounts: questAccountsSlice,
        zohoAuth: zohoAuthSlice,
        upsAuth: upsAuthSlice,
        shopifyAuth: shopifyAuthSlice,
        quickbooksAuth: quickbooksAuthSlice,
        emailAuth: emailAuthSlice,
        clients: clientsSlice,
        notifications: notificationsSlice,
        laboratory: laboratorySlice,
        mro_screens: mroScreenReducer,
        reports: mroReportsReducer,
        laboratorySlice: laboratoryAccountsSlice,
        clientBillingDetails: clientBillingDetailsSlice,
        servicePrices: servicePricesSlice,
        productPrices: productPricesSlice,
        badganator: badganatorSlice,
        orders: ordersSlice,
        questOrders: questOrderSlice,
        questEspManagement: questEspSlice,
        questAccountRequests: questAccountRequestSlice,
        panels: PanelsSlice,
        lssProducts: lssProductsReducer,
        shopifyProducts: shopifyProductsReducer,
        linkedShopifyProducts: linkedShopifyProductsSlice,
        questServices: questServicesSlice,
        clientScreens: clientScreensSlice,
        notes: screenNotesSlice,
        clientEvents: clientEventsSlice,
    }
});

export default store;
