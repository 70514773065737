import { SaveIcons } from "../assets/icons";
import { useState } from "react";

function CopyToClipboard({ text, rowData, onCopy }) {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = (event) => {
        event.stopPropagation();
        navigator.clipboard.writeText(text);
        setCopied(true);
        onCopy(); // Trigger row highlight update
        setTimeout(() => {
            setCopied(false);
        }, 5000);
    };

    return (
        <div>
            {copied ? (
                <div onClick={copyToClipboard}>
                    <img src={SaveIcons.SavedIcon} alt="Copied" />
                </div>
            ) : (
                <div onClick={copyToClipboard}>
                    <img src={SaveIcons.SaveIcon} alt="Copy" />
                </div>
            )}
        </div>
    );
}

export default CopyToClipboard;
