import React, {useState} from 'react';
import "./MroSpecimens.css";
import MroSpecimensRow from "./specimens_row/MroSpecimensRow";
import HeaderRow from "../header_row/HeaderRow";

const MroSpecimens = ({ screensArray, onDownload, dropdownActive, setDropdownActive, statusMapping, requestSort }) => {
    // State to track the filter input value
    const [filterInput, setFilterInput] = useState('');
    const [highlightedRow, setHighlightedRow] = useState(null);

    // Filter screensArray based on filterInput matching device_id or specimen_id
    const filteredScreensArray = screensArray.filter(screen => {
        return (
            screen.device_id?.toString().toLowerCase().includes(filterInput.toLowerCase()) ||
            screen.specimen_id?.toString().toLowerCase().includes(filterInput.toLowerCase())
        );
    });



    // Handle row highlight (force state change)
    const handleRowHighlight = (mroResultId) => {
        console.log("🔹 New Highlighted Row ID:", mroResultId); // Debug log
        setHighlightedRow(prev => (prev === mroResultId ? null : mroResultId)); // Toggle highlight
    };


    return (
        <div className="mro-specimens-container">
            <input
                type="text"
                placeholder="Filter by Specimen ID..."
                value={filterInput}
                onChange={(e) => setFilterInput(e.target.value)}
                className="specimens-filter-input"
            />
            <table>
                <HeaderRow requestSort={requestSort}/>
                <tbody className="scroll-container">
                {filteredScreensArray.map((screen) => (
                    <MroSpecimensRow
                        key={screen.mro_result_id}
                        initialScreenData={screen}
                        onDownload={onDownload}
                        dropdownActive={dropdownActive}
                        setDropdownActive={setDropdownActive}
                        statusMapping={statusMapping}
                        highlightedRow={highlightedRow}
                        onRowHighlight={handleRowHighlight}

                    />
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default MroSpecimens;
