import React, { useState, useEffect } from 'react';
import './MroSpecimensRow.css';
import {useDispatch, useSelector} from "react-redux";
import Select from 'react-select';
import { ActionTrayIcons, GenericIcons } from "../../../../../assets/icons";
import { makePOSTRequest } from "../../../../../services/utils/httpUtils";
import { showMessageModal } from "../../../../../store/modals/modalsSlice";
import CopyToClipboard from "../../../../../utils/copyToClipboard";
import formatDate from "../../../../../utils/dateUtils";
import ActionTray from "../../../../modals/action_tray/ActionTray";

const MroSpecimensRow = ({
                             initialScreenData,
                             onDownload,
                             dropdownActive,
                             setDropdownActive,
                             statusMapping,
                             highlightedRow,
                             onRowHighlight
                         }) => {

    const accessToken = sessionStorage.getItem('accessToken');
    const dispatch = useDispatch();
    const [screenData, setScreenData] = useState(initialScreenData || []);
    const [billableClientData, setBillableClientData] = useState(initialScreenData || []);
    const [downloading, setDownloading] = useState(false);
    const [ccfStatus, setCcfStatus] = useState(null); // Use null as initial value
    const [forceRender, setForceRender] = useState(false); // NEW: Force re-render

    useEffect(() => {
        console.log("📌 Row Re-rendered:", screenData.mro_result_id, "Highlight:", highlightedRow);
        setForceRender(prev => !prev); // NEW: Force state change
    }, [highlightedRow]);

    // Get client data from the Redux store
    const clientData = useSelector(state => state.clients.clientData) || [];
    const clientOptions = clientData.map(client => ({
        label: client.company,
        value: client.id
    }));


    useEffect(() => {
        setCcfStatus(null); // Reset CCF status when screenData changes
    }, [screenData]);

    const userRoles = [
        "VIEW_REJECT_ICON",
        "VIEW_LABORATORY_ICON",
        "VIEWS_NOTES_ICON",
        "VIEWS_EDIT_ICON"
    ];

    const statusOptions = Object.entries(statusMapping).map(([key, value]) => ({
        label: value,
        value: key
    }));

    const handleDropdownChange = async (screenData, newStatusKey) => {
        try {
            const newStatusKeyNumber = parseInt(newStatusKey);
            const newStatusValue = statusMapping[newStatusKeyNumber];

            const formData = new FormData();
            formData.append('mro_result_id', screenData.mro_result_id);
            formData.append('internal_status_id', newStatusKeyNumber);

            await makePOSTRequest(
                '/api/v1/status/update',
                formData,
                accessToken
            );

            // Update the screenData with the new status value
            setScreenData(prevData => ({
                ...prevData,
                internal_status: newStatusValue,
            }));
            dispatch(showMessageModal({ message: "Status updated successfully!", link: null }));
        } catch (error) {
            console.error('Error updating status:', error);
            dispatch(showMessageModal({ message: "Error updating status", link: null }));
        }
    };
    const handleBillableClient = async (screenData, clientId) => {
        try {
            const formData = new FormData();
            formData.append('mro_result_id', screenData.mro_result_id);
            formData.append('client_id', clientId);

            await makePOSTRequest('/api/v1/result/event', formData, accessToken);

            setBillableClientData(prevData => ({
                ...prevData,
                client_id: clientId,
            }));
            dispatch(showMessageModal({ message: "Billable Client updated successfully!", link: null }));
        } catch (error) {
            console.error('Error updating billable client:', error);
            dispatch(showMessageModal({ message: "Error updating billable client", link: null }));
        }
    };

    const handleDownload = async () => {
        setDownloading(true);
        try {
            const response = await onDownload(screenData);

            // console.log('Response:', response);
            // Update CCF status based on response
            setCcfStatus(response.chain_of_custody_available ? 'available' : 'unavailable');

            // Dispatch message modal for download initiation with link
            dispatch(showMessageModal({
                message: "Download initiated successfully.",
                link: response.pdf_url
            }));
        } catch (error) {
            console.error('Error downloading:', error);
            dispatch(showMessageModal({ message: "Failed to initiate download.", link: null }));
        } finally {
            setDownloading(false);
        }
    };

    const isHighlighted = String(highlightedRow) === String(screenData.mro_result_id);

    return (

        <div
            className={`lab-screenData-row ${isHighlighted ? "highlighted-row" : ""}`}
        >
            <div className="mro-page-cell">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {screenData?.specimen_id}
                    <div style={{marginLeft: '10px'}}>
                        <CopyToClipboard
                            text={`${screenData?.specimen_id}`}
                            rowData={screenData}
                            onCopy={() => onRowHighlight(initialScreenData.mro_result_id)} // Call parent function
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className="mro-page-cell">
                    <div className="mro-custom-select-container">
                        <Select
                            options={statusOptions}
                            value={statusOptions.find(option => option.value === screenData.internal_status)}
                            onChange={(selectedOption) => handleDropdownChange(screenData, selectedOption.value)}
                            className="custom-select"
                            classNamePrefix="select"
                            placeholder={screenData?.internal_status}
                            menuPortalTarget={document.body}
                            styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                        />
                    </div>
                    <div className="mro-custom-select-container">
                        <Select
                            options={clientOptions}
                            value={clientOptions.find(option => option.value === screenData.client_id)}
                            onChange={(selectedOption) => handleBillableClient(screenData, selectedOption.value)}
                            className="custom-select"
                            classNamePrefix="select"
                            placeholder="Select Client..."
                            styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                            menuPortalTarget={document.body}
                        />
                    </div>
                </div>
            </div>
            <div className="mro-page-cell">{formatDate(screenData?.collected_on)}</div>
            <div className="mro-page-cell">{formatDate(screenData?.received_date)}</div>
            <div className="mro-page-cell">{screenData?.hours_since_received}</div>
            <div className="mro-page-cell">{screenData?.customer_id}</div>
            <div className="mro-page-cell">{screenData?.laboratory_name}</div>
            <div className="mro-page-cell">{screenData?.panel_code}</div>
            <div className="mro-page-cell-action-tray">
                {downloading ? (
                    <div className="action-tray-placeholder"></div>
                ) : (
                    <ActionTray
                        iconsData={[
                            {
                                icon: ActionTrayIcons.DownloadIcon,
                                alt: "Download",
                                action: handleDownload,
                                permission: "VIEWS_EDIT_ICON"
                            }
                        ]}
                        rowData={screenData}
                        userRoles={userRoles}
                    />
                )}
            </div>
            <div className="mro-page-cell">
                {/* CCF Icon Column */}
                {ccfStatus === 'available' ? (
                    <img src={GenericIcons.ReportIcon} alt="CCF Available" className="ccf-icon"/>
                ) : ccfStatus === 'unavailable' ? (
                    <img src={GenericIcons.ReportIcon} alt="CCF Unavailable" className="ccf-icon dull-icon"/>
                ) : (
                    <div className="ccf-placeholder"></div>
                )}
            </div>
        </div>
    );
};

export default MroSpecimensRow;
