import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {useSelector} from "react-redux";

// Import Route Components
import PublicHomePage from '../pages/home/public_home/PublicHome';
import ShopifyHomePage from "../pages/home/shopify_home/ShopifyHome";
import LoginPage from '../pages/user/login/Login';
import ClientOrdersPage from '../pages/client_orders/ClientOrders';
import ShopifyProtectedRoute from './ShopifyProtectedRoute';
import ShopifySpeedProductPage from "../pages/shopify/speed/product/ShopifySpeedProductPage";
import PublicVerifyPage from "../pages/user/verify/PublicVerify";
import PublicFreddyDashboard from "../pages/dashboards/public_freddy_dashboard/PublicFreddyDashboard";
import PanelsPage from '../pages/panels/Panels';
import OrganizationsPage from "../pages/organizations/OrganizationsPage";
import ClientScreensPage from "../pages/clients/screens/ClientScreensPage";
import BillableEventsPage from "../pages/billable_events/BillableEvents";
import SpecimenSearchModal from "../components/modals/specimen_search/SpecimenSearchModal";
import {SignInPage} from "../pages/user/sign_in/SignIn";
import {ShopifyAuthProvider} from "../helpers/auth/shopifyAuthHelper";

const UnauthenticatedRoutes = ({instance}) => {
    // Get authentication state and permissions
    const lssCustomerDetails = useSelector((state) => state.shopifyUser.lssCollectorDetails);
    const publicToken = useSelector((state) => state.publicUser?.publicAccessToken);

    return (
        <BrowserRouter>
            {/* Wrap Entire App in ShopifyAuthProvider */}
            <ShopifyAuthProvider>
                <Routes>

                    {/* Admin Login (Accessible by Staff/Admins) */}
                    <Route path="/login" element={<LoginPage instance={instance}/>}/>

                    {/* Public Home Page (Accessible by Anyone) */}
                    <Route path="/" element={<PublicHomePage instance={instance}/>}/>

                    {/* Private Invite-Only Portal */}
                    <Route path="/sign-in" element={<SignInPage/>}/>

                    {/* Public route for verifying users */}
                    <Route path="/verify" element={<PublicVerifyPage instance={instance}/>}/>

                    {/* Shopify Protected Routes */}
                    <Route path="/shopify/home" element={
                        <ShopifyProtectedRoute>
                            {/*<ShopifyUserRegistration>*/}
                                <ShopifyHomePage/>
                            {/*</ShopifyUserRegistration>*/}
                        </ShopifyProtectedRoute>
                    }/>

                    {/* Conditional Route: Only Show Panels Page If User Can Manage Panels */}
                    {lssCustomerDetails?.can_manage_panels && (
                        <Route path="/panels" element={
                            <ShopifyProtectedRoute>
                                {/*<ShopifyUserRegistration>*/}
                                    <PanelsPage/>
                                {/*</ShopifyUserRegistration>*/}
                            </ShopifyProtectedRoute>
                        }/>
                    )}

                    {/* Conditional Route: Only Show Organizations Page If User Can Manage Screeners */}
                    {lssCustomerDetails?.can_manage_screeners && (
                        <Route path="/organizations" element={
                            <ShopifyProtectedRoute>
                                {/*<ShopifyUserRegistration>*/}
                                    <OrganizationsPage/>
                                {/*</ShopifyUserRegistration>*/}
                            </ShopifyProtectedRoute>
                        }/>
                    )}

                    {/* Conditional Route: Only Show Specimens Page If User Can Manage Results */}
                    {lssCustomerDetails?.can_manage_results && (
                        <Route path="/specimens" element={
                            <ShopifyProtectedRoute>
                                {/*<ShopifyUserRegistration>*/}
                                    <ClientScreensPage/>
                                {/*</ShopifyUserRegistration>*/}
                            </ShopifyProtectedRoute>
                        }/>
                    )}

                    {/* Conditional Route: Only Show Events Page If User Can Manage Results and Order Cartons */}
                    {lssCustomerDetails?.can_manage_results && lssCustomerDetails.can_order_cartons && (
                        <Route path="/events" element={
                            <ShopifyProtectedRoute>
                                {/*<ShopifyUserRegistration>*/}
                                    <BillableEventsPage/>
                                {/*</ShopifyUserRegistration>*/}
                            </ShopifyProtectedRoute>
                        }/>
                    )}

                    {/* Conditional Route: Only Show Client Orders If User Can Order Cartons */}
                    {lssCustomerDetails?.can_order_cartons && (
                        <Route path="/orders" element={
                            <ShopifyProtectedRoute>
                                {/*<ShopifyUserRegistration>*/}
                                    <ClientOrdersPage/>
                                {/*</ShopifyUserRegistration>*/}
                            </ShopifyProtectedRoute>
                        }/>
                    )}

                    {/* Public Product Page */}
                    <Route path="/product" element={<ShopifySpeedProductPage/>}/>

                    {/* Public Specimen Search */}
                    <Route path="/specimen/search" element={<SpecimenSearchModal/>}/>

                    {/* Freddy Dashboard Route (Shown When User Has a Valid Token) */}
                    {publicToken && (
                        <Route path="/home" element={<PublicFreddyDashboard/>}/>
                    )}

                </Routes>
            </ShopifyAuthProvider>
        </BrowserRouter>
    );
};

export default UnauthenticatedRoutes;
