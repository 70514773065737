import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchPanelsByClientId} from "../../services/api/v1/panelsService";
import {useSelector} from "react-redux";

// Define the async thunk for fetching panels by organization ID
export const getPanelsByClientId = createAsyncThunk(
    'panels/getPanelsByClientId',
    async ({clientId}, thunkAPI) => {
        let accessToken = sessionStorage.getItem("accessToken");

        if (!accessToken) {
            accessToken = sessionStorage.getItem("shopifyIdToken");
        }


        return await fetchPanelsByClientId({clientId, accessToken});
    }
);

const panelSlice = createSlice({
    name: 'panels',
    initialState: {
        panels: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPanelsByClientId.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPanelsByClientId.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.panels = action.payload || [];
            })
            .addCase(getPanelsByClientId.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default panelSlice.reducer;
