import React, { useEffect, useState } from "react";
import "./BillableEvents.css";
import BillableEventsList from "../../components/billable_events/BillableEventsList";
import ShopifyHeader from "../../components/headers/shopify_header/ShopifyHeader";
import { useDispatch, useSelector } from "react-redux";
import { getClientEvents } from "../../store/events/eventSlice";
import LabFusionLogo from "../../assets/logo/LabFusionLogo.png";
import LabbLogo from "../../assets/logo/LabbLogo.png";
import { clientBillableEvents } from "../../testData/clientBillableEvents";
import BillableEventsSummary from "../../components/billable_events/summary/BillableEventsSummary";


const mapSpecimens = (data) => {
    if (!data || !Array.isArray(data.specimens)) {
        console.warn("Invalid specimens data:", data);
        return [];
    }

    return data.specimens.map((specimen) => ({
        screen_id: specimen.id,
        specimen_id: specimen.device_id,
        collected_on: specimen.collected_on,
        status: "Billable",
        organization_name: specimen.location_name || "Unknown",
        client_name: specimen.client_name || "N/A",
        product_name: specimen.product_name || "Unknown",
        product_price: specimen.product_retail_price || 0.0,
        selected_laboratory: specimen.selected_laboratory || "N/A",
        billable_events: specimen.billable_events || [],
        total_billable_count: specimen.billable_events.length || 0,
        isBillable: specimen.billable_events.length > 0,
    }));
};



const BillableEventsPage = () => {
    const dispatch = useDispatch();
    const [events, setEvents] = useState([]);
    const [selectedSpecimens, setSelectedSpecimens] = useState(new Set());
    const [clientAccount, setClientAccount] = useState("");
    const [totalBillableCount, setTotalBillableCount] = useState(0);
    const lssCustomerDetails = useSelector(
        (state) => state.shopifyUser.lssCollectorDetails
    );
    // Uncomment for live API data:
    const clientEventsData = useSelector((state) => state.clientEvents.clientEvents) || null;

    // For testing, we use clientBillableEvents
    // const clientEventsData = clientBillableEvents;

    const now = new Date();
    const defaultStartDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 30
    )
        .toISOString()
        .replace("T", " ")
        .split(".")[0];
    const defaultEndDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
    )
        .toISOString()
        .replace("T", " ")
        .split(".")[0];

    // Uncomment to fetch data from the API when ready:

    useEffect(() => {
        if (lssCustomerDetails?.client_id) {
            dispatch(
                getClientEvents({
                    clientId: lssCustomerDetails.client_id,
                    start_date: defaultStartDate,
                    end_date: defaultEndDate,
                })
            );
        }
    }, [dispatch, lssCustomerDetails, defaultStartDate, defaultEndDate]);



    useEffect(() => {
        let processedEvents = [];
        let totalBillable = 0;

        if (clientEventsData && Array.isArray(clientEventsData.specimens)) {
            processedEvents = mapSpecimens(clientEventsData);
            totalBillable = processedEvents.reduce(
                (sum, event) => sum + event.billable_events.length,
                0
            );
        }

        processedEvents.sort((a, b) => {
            if (a.isBillable && !b.isBillable) return -1;
            if (!a.isBillable && b.isBillable) return 1;
            return new Date(b.collected_on) - new Date(a.collected_on);
        });

        setEvents(processedEvents);
        setTotalBillableCount(totalBillable);
    }, [clientEventsData]);


    // Toggle specimen selection using a Set for efficiency
    const handleSpecimenSelect = (identifier) => {
        setSelectedSpecimens((prevSelected) => {
            const newSelection = new Set(prevSelected);
            if (newSelection.has(identifier)) {
                newSelection.delete(identifier);
            } else {
                newSelection.add(identifier);
            }
            return newSelection;
        });
    };

    const hostname = window.location.hostname;
    const logoSrc = hostname === "fusion.labb.com" ? LabFusionLogo : LabbLogo;
    const docTitle =
        hostname === "fusion.labb.com"
            ? "Lab Fusion | Billable Events"
            : "Labb | Billable Events";

    useEffect(() => {
        document.title = docTitle;
    }, [docTitle]);

    const handleBulkActionSubmit = () => {
        if (!clientAccount) {
            alert("Please enter a client account number.");
            return;
        }
        console.log(
            "Bulk rebill request for:",
            Array.from(selectedSpecimens),
            "to client account:",
            clientAccount
        );
        alert(
            `Bulk rebill requested for ${selectedSpecimens.size} specimens to client account ${clientAccount}.`
        );
        setSelectedSpecimens(new Set());
        setClientAccount("");
    };

    return (
        <div className="billable-events-page-container">
            <ShopifyHeader logoSrc={logoSrc} />

            <section className="billable-events-section list-section">
                <BillableEventsSummary totalBillableCount={totalBillableCount} />
                <BillableEventsList
                    events={events}
                    selectedSpecimens={selectedSpecimens}
                    onSpecimenSelect={handleSpecimenSelect}
                />
            </section>

            {selectedSpecimens.size > 0 && (
                <div className="bulk-rebill-bar">
                    <input
                        type="text"
                        placeholder="Enter Client Account Number"
                        value={clientAccount}
                        onChange={(e) => setClientAccount(e.target.value)}
                        className="bulk-rebill-input"
                    />
                    <button onClick={handleBulkActionSubmit} className="bulk-rebill-btn">
                        ReBill ({selectedSpecimens.size} selected)
                    </button>
                </div>
            )}
        </div>
    );
};

export default BillableEventsPage;
