import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import './SpecimenSearchModal.css';

const SpecimenSearchModal = () => {
    const dispatch = useDispatch();
    const [specimenId, setSpecimenId] = useState('');
    const [lastSearchedId, setLastSearchedId] = useState('');
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    let API_URL = process.env.REACT_APP_API_URL;

    // Prevent duplicate searches
    const isDuplicateSearch = specimenId === lastSearchedId;

    // Sanitize input (basic security measure)
    const sanitizeInput = (input) => input.replace(/[^a-zA-Z0-9_-]/g, '');

    // Fetch specimen status
    const fetchSpecimenStatus = async () => {
        if (!specimenId.trim() || isDuplicateSearch) return;

        const sanitizedId = sanitizeInput(specimenId);
        if (!sanitizedId) {
            setError('Invalid Specimen ID format.');
            return;
        }

        setLoading(true);
        setError(null);
        setStatus(null);

        try {
            const response = await axios.get(`${API_URL}/${sanitizedId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200 && response.data) {
                setStatus(response.data.status);
                setLastSearchedId(sanitizedId);
            } else {
                setError('No data found for this Specimen ID.');
            }
        } catch (err) {
            setError(err.response?.data?.message || 'Error retrieving specimen status.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="specimen-search-modal">
            <div className="specimen-search-container">
                <div className="specimen-search-form">
                    <input
                        type="text"
                        value={specimenId}
                        onChange={(e) => setSpecimenId(e.target.value)}
                        placeholder="Enter Specimen ID"
                        className="specimen-input"
                        disabled={loading}
                    />
                    <button
                        onClick={fetchSpecimenStatus}
                        disabled={loading || isDuplicateSearch || !specimenId.trim()}
                        className={`search-btn ${loading || isDuplicateSearch ? 'disabled' : ''}`}
                    >
                        {loading ? 'Searching...' : 'SEARCH'}
                    </button>
                    {status && <p className="status-message success">Status: {status}</p>}
                    {error && <p className="status-message error">{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default SpecimenSearchModal;
