import React, {useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import './QuestEspManagementModal.css';
import {
    clearQuestEspManagementData, requestClientCollectors,
    requestEspCollectorAccess,
    toggleQuestEspManagementModal
} from "../../../store/quest_esp/questEspSlice";
import {PrimaryButton} from "../../buttons/primary_button/PrimaryButton";
import {IconButton} from "../../buttons/icon_button/IconButton";
import {GenericIcons} from "../../../assets/icons";
import {fetchAllClientShopifyAccounts} from "../../../store/clients/clientsSlice";


const QuestEspManagementModal = () => {
    const dispatch = useDispatch();
    const isQuestEspManagementVisible = useSelector(state => state.questEspManagement.isQuestEspManagementVisible);
    const lssEspCollectorsDetails = useSelector(state => state.questEspManagement.lssEspCollectorsDetails);
    const freddyAccountNumbers = useSelector(state => state.clients?.clientFreddyAccountsDetails);
    const lssCustomerDetails = useSelector((state) => state.shopifyUser.lssCollectorDetails);

    const [selectedCollector, setSelectedCollector] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [selectedEspAction, setSelectedEspAction] = useState([]);

    const fetchClientCollectors = useCallback(() => {
        if (lssCustomerDetails) {
            dispatch(requestClientCollectors({
                clientId: lssCustomerDetails?.client_id
            }));
        }
    }, [dispatch, lssCustomerDetails]);

    const fetchFreddyClientAccounts= useCallback(() => {
        if (lssCustomerDetails) {
            dispatch(fetchAllClientShopifyAccounts({clientId: lssCustomerDetails?.client_id}));
        }
    }, [dispatch, lssCustomerDetails]);

    useEffect(() => {
        fetchClientCollectors();
        fetchFreddyClientAccounts();
    }, [fetchClientCollectors, fetchFreddyClientAccounts]);

    const collectorsOptions = lssEspCollectorsDetails?.map(collector => ({
        label: `${collector.collector_first_name} ${collector.collector_last_name}`,
        value: collector.collector_id
    }));

    const accountNumberOptions = freddyAccountNumbers?.filter(account => !account.is_draft)?.map(account => ({
        label: `${account.laboratory_account_number} - ${account.laboratory_client_name}`,
        value: account.laboratory_account_id
    }));

    const espActionOptions = [
        {label: "Add", value: "Add"},
        {label: "Remove", value: "Remove"},
    ];

    const handleSubmitRequest = () => {
        dispatch(requestEspCollectorAccess({
            user: selectedCollector.value,
            accounts: selectedAccounts,
            action: selectedEspAction,
        }));
    };


    const handleCloseModal = () => {
        dispatch(toggleQuestEspManagementModal());
        dispatch(clearQuestEspManagementData())
    };

    if (!isQuestEspManagementVisible) return null;

    return (
        <div className="esp-management-modal-overlay">
            <div className="esp-management-modal">
                <div className="esp-management-modal-header">
                    <IconButton hoverIcon={GenericIcons.ExitIcon} defaultIcon={GenericIcons.CloseIcon} onClick={handleCloseModal} />
                </div>
                <h2 className="esp-management-modal-title">Quest ESP Management</h2>

                <div className="modal-section">
                    <Select
                        options={collectorsOptions}
                        value={selectedCollector}
                        onChange={setSelectedCollector}
                        placeholder="Choose collector"
                        isClearable={true}
                        closeMenuOnSelect={true}
                    />
                </div>

                <div className="modal-section">
                    <Select
                        options={accountNumberOptions}
                        value={selectedAccounts}
                        onChange={setSelectedAccounts}
                        placeholder="Choose account numbers"
                        isClearable={true}
                        isMulti={true}
                        closeMenuOnSelect={false}
                    />
                </div>
                <div className="modal-section">
                    <Select
                        options={espActionOptions}
                        value={selectedEspAction}
                        onChange={setSelectedEspAction}
                        placeholder="Choose action..."
                        isClearable={true}
                        closeMenuOnSelect={true}
                    />

                </div>

                <PrimaryButton className="submit-request-button" onClick={handleSubmitRequest}>
                    Submit Request
                </PrimaryButton>
            </div>
        </div>
    );
};

export default QuestEspManagementModal;
