import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getShopifyAuth } from "../../store/external/shopifyAuthSlice";
import { fetchCollectorByEmail, getShopifyCustomerDetails } from "../../store/user/shopifyUserSlice";

const ShopifyAuthContext = createContext();

export const ShopifyAuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const { access_token, expires_at } = useSelector(state => state.shopifyAuth.shopifyAuth);
    const customerDetails = useSelector(state => state.shopifyUser.customerDetails);
    const isLoadingAuth = useSelector(state => state.shopifyAuth.isLoading);
    const isLoadingCustomer = useSelector(state => state.shopifyUser.loading);

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const shopifyGrantToken = sessionStorage.getItem("shopifyGrantToken");

        if (shopifyGrantToken && !access_token) {
            dispatch(getShopifyAuth())
                .unwrap()
                .then(() => setIsAuthenticated(true))
                .catch(() => setIsAuthenticated(false));
        } else if (access_token && Date.now() < expires_at) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [dispatch, access_token, expires_at]);

    useEffect(() => {
        if (!access_token) return;

        const fetchCustomerAndCollectorDetails = async () => {
            try {
                console.log("Fetching Shopify customer details...");
                const customerData = await dispatch(getShopifyCustomerDetails({ shopifyOauthToken: access_token })).unwrap();

                if (!customerData?.emailAddress?.emailAddress) {
                    throw new Error("Invalid customer data.");
                }

                console.log("Fetching collector details...");
                await dispatch(fetchCollectorByEmail({ formData: { collector_email: customerData.emailAddress.emailAddress, shopify_oauth_token: access_token } })).unwrap();
            } catch (error) {
                console.error("Error fetching customer details:", error);
                setError(error.message || "An unexpected error occurred.");
            }
        };

        fetchCustomerAndCollectorDetails();
    }, [dispatch, access_token]);

    return (
        <ShopifyAuthContext.Provider value={{ isAuthenticated, isLoadingAuth, isLoadingCustomer, customerDetails, error }}>
            {children}
        </ShopifyAuthContext.Provider>
    );
};

export const useShopifyAuth = () => useContext(ShopifyAuthContext);
