import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './ClientSites.css';
import {IconButton} from "../../buttons/icon_button/IconButton";
import {GenericIcons} from "../../../assets/icons";
import {toggleClientSitesModal} from "../../../store/clients/clientsSlice";
import {createQuickbooksCustomer} from "../../../store/external/quickbooksCustomerSlice";
import {PrimaryButton} from "../../buttons/primary_button/PrimaryButton";
import {showMessageModal} from "../../../store/modals/modalsSlice";


const ClientSitesModel = () => {
    const isVisible = useSelector((state) => state.clients?.isSitesModalVisible);
    const clientSiteDetails = useSelector((state) => state.clients?.clientSites);
    const dispatch = useDispatch();
    const [selectedSite, setSelectedSite] = useState(null);
    let quickbooksAccessToken = useSelector((state) => state.quickbooksAuth?.quickbooksAuth.access_token);

    if (quickbooksAccessToken === null) {
        quickbooksAccessToken = sessionStorage.getItem('quickbooksAccessToken');
    }

    if (!isVisible) return null;

    const handleClose = () => {
        dispatch(toggleClientSitesModal(false));
    };
    const handleCreateQuickbooksCustomer = async () => {
        try {
            if (!selectedSite) return; // Avoid empty submissions
            await dispatch(createQuickbooksCustomer({quickbooksAccessToken, siteId: selectedSite}))
                .unwrap()
                .then(() => {
                    dispatch(showMessageModal({
                        message: "QuickBooks Customer created successfully.",
                        isModalOpen: true
                    }));
                })
                .catch((error) => {
                    dispatch(showMessageModal({
                        message: `Failed to create QuickBooks Customer: ${error.message || "Unknown error"}`,
                        link: null,
                        isModalOpen: true
                    }));
                });
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(toggleClientSitesModal(false));
        }

    };



    const toggleSiteSelection = (siteId) => {
        setSelectedSite((prevSelectedSite) => (prevSelectedSite === siteId ? null : siteId));
    };

    return (
        <div className="client-sites-modal-overlay">
            <div className="client-sites-modal">
                <div className="client-sites-modal-header">
                    <IconButton
                        hoverIcon={GenericIcons.ExitIcon}
                        defaultIcon={GenericIcons.CloseIcon}
                        onClick={handleClose}
                        label="Close"
                    />
                </div>
                <div className="client-sites-modal-content">
                    <div>
                        {clientSiteDetails?.length ? (
                            clientSiteDetails.map((detail) => (
                                <div
                                    key={detail.location_id}
                                    className={`client-site-detail ${selectedSite === detail.location_id ? 'selected-site' : ''}`}
                                    onClick={() => toggleSiteSelection(detail.location_id)}
                                >
                                    <div className="client-site-info">
                                        <h3 className="site-name">{detail.location_name}</h3>
                                        <p className="site-address">
                                            {detail.address}, {detail.city}, {detail.state} {detail.zip}, {detail.country}
                                        </p>
                                        <p className="site-phone">{detail.phone || "N/A"}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="no-client-sites">No client sites available.</p>
                        )}

                        <PrimaryButton
                            className="create-quickbooks-btn"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCreateQuickbooksCustomer();
                            }}
                            disabled={!selectedSite}
                        >
                            Create QuickBooks Customer
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientSitesModel;
