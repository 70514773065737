import React from "react";
import "./BillableEventItem.css";

const BillableEventItem = ({event, isSelected, onSpecimenSelect}) => {
    return (
        <div
            className={`billable-event-item ${isSelected ? "selected" : ""}`}
            onClick={() => onSpecimenSelect(event.screen_id)}
        >
            <div className="event-header">
                <div className="header-left">
                    <div className="event-master-result">{event.specimen_id}</div>
                    <div className="event-date">
                        {new Date(event.collected_on).toLocaleDateString()}
                    </div>
                </div>
            </div>

            <div className="event-details">
                <div className="event-client">{event.product_name}</div>
            </div>

            {/* Display Billable Events Inline */}
            {event.billable_events.length > 0 && (
                <div className="billable-events-list">
                    {event.billable_events.map((billingItem, index) => (
                        <div>
                            <div className="billable-tag" key={index}>
                                {billingItem.billable_event_id}
                            </div>
                        </div>
                    ))}
                </div>
            )}

        </div>
    );
};

export default BillableEventItem;
