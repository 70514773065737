// freddy-react/src/components/specimen_tacking/screens/SpecimenTrackingTable.js

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './MroSpecimensTable.css';
import {subDays, subMonths} from "date-fns";
import {getStatuses, setDateRange, setFilter} from "../../../store/filters/filtersSlice";
import {useDispatch, useSelector} from "react-redux";
import MroSpecimensFilters from "./filters/MroSpeicmensFilters";
import MroSpecimens from "./mro_specimens/MroSpecimens";
import {getLaboratoryBillingRecords} from "../../../store/laboratory/laboratorySlice";
import formatDate from "../../../utils/dateUtils";


// Helper function to get unique values from an array
const getUniqueValues = (data, key) => {
    return [...new Set(data.map(item => item[key]))];
};

const MroSpecimensTable = ({screensArray, onDownload, onFilterChange, defaultDateRange}) => {
    const [specimenIds, setSpecimenIds] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [selectedSpecimenIds, setSelectedSpecimenIds] = useState(null);
    const [specimensArray, setSpecimensArray] = useState([]); // New state for specimensArray
    const [dropdownActive, setDropdownActive] = useState(null);
    const [accountNumber, setAccountNumber] = useState(null);
    const [collectedOn, setCollectedOn] = useState(null);
    const [laboratory, setLaboratory] = useState(null);
    const dispatch = useDispatch();
    const {
        status,
        statuses,
        dateRange,
    } = useSelector((state) => state.filters);
    const lastWeek = [subDays(new Date(), 6), new Date()];
    const lastMonth = [subMonths(new Date(), 1), new Date()];
    const last3Months = [subMonths(new Date(), 3), new Date()];
    const [screenParams, setScreenParams] = useState()
    const statusMapping = {
        1: 'ORDER GENERATED',
        2: 'SHIPPED',
        3: 'IN TRANSIT',
        4: 'DELIVERED',
        5: 'REGISTERED IN LAB',
        6: 'SENT TO MRO',
        7: 'ATTENTION REQUIRED',
        8: 'PENDING REVIEW',
        9: 'APPROVED',
        10: 'PENDING UPLOAD',
        11: 'COMPLETE',
        12: 'SEND TO LAB',
        13: 'SEND TO MRO',
        14: 'SENT TO LAB'
    }



    const uniqueCollectedOn = useMemo(() => getUniqueValues(screensArray, 'collected_on'), [screensArray]);
    const uniqueAccountNumbers = useMemo(() => getUniqueValues(screensArray, 'account_number'), [screensArray]);
    const uniqueLaboratories = useMemo(() => getUniqueValues(screensArray, 'laboratory'), [screensArray]);

    useEffect(() => {
        // Extract unique specimen IDs
        const newSpecimenIds = Array.from(new Set(screensArray.map(screen => screen.specimen_id)));
        const updatedSpecimensArray = newSpecimenIds.map(id => ({
            label: id,
            value: id
        }));
        setSpecimensArray(updatedSpecimensArray); // Update the state
    }, [screensArray]);

    // Initialize the state with the default date range
    useEffect(() => {
        handleDateRangeChange(defaultDateRange);
    }, []);


    useEffect(() => {
        if (uniqueAccountNumbers.length > 0) {
            setAccountNumber(uniqueAccountNumbers[0]);
            dispatch(setFilter({ filterName: 'account_number', value: uniqueAccountNumbers[0] }));
        }

        if (uniqueCollectedOn.length > 0) {
            setCollectedOn(uniqueCollectedOn[0]);
            dispatch(setFilter({ filterName: 'collected_on', value: uniqueCollectedOn[0] }));
        }

        if (uniqueLaboratories.length > 0) {
            setLaboratory(uniqueLaboratories[0]);
            dispatch(setFilter({ filterName: 'laboratory', value: uniqueLaboratories[0] }));
        }
    }, [uniqueAccountNumbers, uniqueCollectedOn, uniqueLaboratories]);




    const handleDateRangeChange = (value) => {
        const isoDates = value?.map(date => date.toISOString().split('T')[0]);

        // If isoDates is defined and has exactly two dates, structure them as start_date and end_date
        let filterValues = {};
        if (isoDates && isoDates.length === 2) {
            filterValues = {
                start_date: isoDates[0],
                end_date: isoDates[1],
            };
        }

        // Dispatch action to set date range in the Redux store
        dispatch(setDateRange({ value: isoDates }));

        // Assuming onApplyFilters expects the name of the filter and its value(s)
        // Here, filterValues is an object with start_date and end_date
        const filterName = "date_range";
        onFilterChange(filterName, filterValues);
    };

    const creatableSelectValues = {
        // deviceIds: {
        //     options: specimensArray || [],
        //     value: selectedSpecimenIds,
        //     onChange: handleInputChange('specimen_id'),
        //     placeholder: "Search by specimen id",
        //     isMulti: true
        // },
        // status: {
        //     options: statusArray || [],
        //     value: status,
        //     onChange: handleInputChange('status'),
        //     placeholder: "Search by status",
        //     isMulti: true
        // },
        // collectionDates: {
        //     options: uniqueCollectedOn.map(date => ({ label: formatDate(date), value: date })) || [],
        //     value: collectedOn,
        //     onChange: handleInputChange('collected_on'),
        //     placeholder: "Search by collected on",
        //     isMulti: true
        // },
        // account_numbers: {
        //     options: uniqueAccountNumbers.map(number => ({ label: number, value: number })) || [],
        //     value: accountNumber,
        //     onChange: handleInputChange('account_number'),
        //     placeholder: "Search by account number",
        //     isMulti: true
        // },
        // laboratories: {
        //     options: uniqueLaboratories.map(lab => ({ label: lab, value: lab })) || [],
        //     value: laboratory,
        //     onChange: handleInputChange('laboratory'),
        //     placeholder: "Search by laboratory",
        //     isMulti: true
        // },
    };


    // Sorting function to be used in the MroSpecimens component


    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };


    const sortedScreensArray = useMemo(() => {
        let sortableItems = [...screensArray];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [screensArray, sortConfig]);


    return (
        <div className="mro-specimens-table-container">
            <MroSpecimensFilters
                creatableSelectValues={creatableSelectValues}
                onApplyFilters={onFilterChange}
            />
            <div className="table-container">
                <MroSpecimens
                    screensArray={sortedScreensArray}
                    onDownload={onDownload}
                    dropdownActive={dropdownActive}
                    setDropdownActive={setDropdownActive}
                    statusMapping={statusMapping}
                    screenParams={screenParams}
                    requestSort={requestSort}
                />
            </div>
        </div>
    );
};

export default MroSpecimensTable;
