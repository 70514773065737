import React from "react";
import Masonry from "react-masonry-css";
import "./BillableEventsList.css";
import BillableEventItem from "./item/BillableEventItem";

const BillableEventsList = ({ events, selectedSpecimens, onSpecimenSelect }) => {
    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        768: 2,
        500: 1,
    };

    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="billable-events-masonry-grid"
            columnClassName="billable-events-masonry-column"
        >
            {events.map((event) => (
                <BillableEventItem
                    key={event.screen_id}
                    event={event}
                    isSelected={selectedSpecimens.has(event.screen_id)}
                    onSpecimenSelect={onSpecimenSelect}
                />
            ))}
        </Masonry>
    );
};

export default BillableEventsList;
