import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchClientEvents} from "../../services/api/v1/clientEventService";

// Async thunk for fetching emails
export const getClientEvents = createAsyncThunk(
    'events/fetchClientEvents',
    async ({clientId, start_date, end_date}, {getState}) => {
        const accessToken = sessionStorage.getItem('shopifyIdToken');

        const formData = new FormData();
        formData.append('client_id', clientId);
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);

        return await fetchClientEvents(formData, accessToken);
    }
);

const initialState = {
    clientEvents: { specimens: [] },
    selectedLineItems: [], // Ensure this exists
    status: 'idle',
    error: null
};


const clientEventsSlice = createSlice({
    name: 'clientEvents',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getClientEvents.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getClientEvents.fulfilled, (state, action) => {
                state.status = 'idle';
                state.clientEvents = action.payload;
            })
            .addCase(getClientEvents.rejected, (state, action) => {
                state.status = 'idle';
                state.error = action.error.message;
            });
    },
});

export default clientEventsSlice.reducer;
